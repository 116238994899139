import { Injectable } from '@angular/core';

import { environment } from './environment';
import { Environment } from './environment.base';

export { Environment };

@Injectable({
    providedIn: 'root',
})
export class EnvironmentService implements Environment {
    readonly isProduction = environment.isProduction;
    readonly dev = environment.dev;
    readonly api = environment.api;
    readonly redirect = environment.redirect;
}
