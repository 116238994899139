import { Action, createReducer, on } from '@ngrx/store';

import { clearAuthMethod, setAuthMethod } from 'app/auth/store/auth-method/auth-method.actions';

import { AuthMethodState } from './auth-method.state';

export const authMethodKey = 'authMethod';

const initialState: AuthMethodState = null;

const reducer = createReducer<AuthMethodState>(
    initialState,
    on(clearAuthMethod, () => initialState),
    on(setAuthMethod, (state, { authMethod }) => authMethod),
);

export function authMethodReducer(state: AuthMethodState | undefined, action: Action): AuthMethodState {
    return reducer(state, action);
}
