import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IpWhitelistingEntry, WhitelistEntry } from 'app/admin/store/ip-whitelisting/ip-whitelisting.state';
import { IpWhitelistingOperationInfo, IpWhitelistingOperationInfoDTO } from 'app/core/services/ip-whitelisting/dto';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class IpWhitelistingService {
    constructor(private readonly http: HttpClient) {
    }

    getClientList(): Observable<string[]> {
        return this.http.get<string[]>(`${environment.api.baseUrl}/clients`).pipe(map((clientCodes) => clientCodes.sort()));
    }

    getWhitelistedIpsForAllClients(): Observable<Blob> {
        return this.http.get(`${environment.api.baseUrl}/allowed-ips/export-csv`, { headers: { Accept: 'text/csv' }, responseType: 'blob' });
    }

    getWhitelistedIpsForClient(clientCode: string): Observable<IpWhitelistingEntry> {
        return this.http.get<IpWhitelistingEntry>(`${environment.api.baseUrl}/${clientCode}/allowed-ips`);
    }

    getWhitelistOperationLogForClient(clientCode: string): Observable<IpWhitelistingOperationInfo[]> {
        return this.http.get<IpWhitelistingOperationInfoDTO[]>(`${environment.api.baseUrl}/${clientCode}/allowed-ips/log`)
            .pipe(map((logs) => logs.map((log) => ({ ...log, date: new Date(log.date) }))));
    }

    addOrUpdateWhitelistedIpForClient(clientCode: string, newWhitelistedIpEntry: WhitelistEntry): Observable<IpWhitelistingEntry> {
        return this.http.post<IpWhitelistingEntry>(`${environment.api.baseUrl}/${clientCode}/allowed-ips`, { whitelistEntry: newWhitelistedIpEntry });
    }

    removeWhitelistedIpForClient(clientCode: string, whitelistEntry: WhitelistEntry): Observable<IpWhitelistingEntry> {
        const options = { body: { whitelistEntry } };
        return this.http.request<IpWhitelistingEntry>('DELETE', `${environment.api.baseUrl}/${clientCode}/allowed-ips`, options);
    }

    setWhitelistingEnabledForClient(clientCode: string, enabled: boolean): Observable<IpWhitelistingEntry> {
        return this.http.patch<IpWhitelistingEntry>(`${environment.api.baseUrl}/${clientCode}/allowed-ips`, { enabled });
    }
}
