import { baseEnvironment, Environment } from './environment.base';

const inAws = window.location.hostname.startsWith('webauth');

function getDefaultOrigin(): string {
    if (window.location.hostname.endsWith('.hedgeserv.com')) {
        return 'https://access.hedgeserv.com';
    } else if (window.location.hostname.endsWith('.hsuat.com')) {
        return 'https://access.hsuat.com';
    }

    return window.location.origin;
}

function getDefaultRedirectUrl(): string {
    if (inAws) {
        return window.location.origin.replace('webauth', 'lander');
    } else {
        // we're on-prem (access.hs.com) and just want to return to /
        return window.location.origin;
    }
}

function getApiBaseUrl(origin: string): string {
    if (!inAws) {
        return `${origin}/wauth/api`;
    }

    return `${window.location.origin}/webauth/api`;
}

const defaultOrigin = getDefaultOrigin();

export const environment: Environment = {
    ...baseEnvironment,
    dev: false,
    api: {
        ...baseEnvironment.api,
        baseUrl: getApiBaseUrl(defaultOrigin),
    },
    redirect: {
        ...baseEnvironment.redirect,
        defaultOrigin,
        defaultUrl: getDefaultRedirectUrl(),
    },
};
