import { Routes } from '@angular/router';

import { LoginPageGuard } from 'app/auth/guards/login-page.guard';
import { PasscodeGuard } from 'app/auth/guards/passcode.guard';
import { PasswordChangeGuard } from 'app/auth/guards/password-change.guard';
import { TwoFactorGuard } from 'app/auth/guards/two-factor.guard';

export const routes: Routes = [
    {
        path: '',
        loadComponent: async () => (await import('./auth.component')).AuthComponent,
        children: [
            { path: '', pathMatch: 'full', redirectTo: 'login' },
            {
                path: 'login',
                loadComponent: async () => (await import('./containers/login-page/login-page.component')).LoginPageComponent,
                pathMatch: 'full',
                canActivate: [LoginPageGuard],
            },
            {
                path: 'logout',
                loadComponent: async () => (await import('./containers/logout/logout.component')).LogoutComponent,
                pathMatch: 'full',
            },
            {
                path: 'authentication-selection',
                loadComponent: async () => (await import('./containers/authentication-selection/authentication-selection.component')).AuthenticationSelectionComponent,
                pathMatch: 'full',
                canActivate: [TwoFactorGuard],
            },
            {
                path: 'passcode',
                loadComponent: async () => (await import('./containers/passcode/passcode.component')).PasscodeComponent,
                pathMatch: 'full',
                canActivate: [PasscodeGuard],
            },
            {
                path: 'push',
                loadComponent: async () => (await import('./containers/push/push.component')).PushComponent,
                pathMatch: 'full',
                canActivate: [TwoFactorGuard],
            },
            {
                path: 'change-password',
                loadComponent: async () => (await import('./containers/password-change-page/password-change-page.component')).PasswordChangePageComponent,
                pathMatch: 'full',
                canActivate: [PasswordChangeGuard],
            },
        ],
    },
];
