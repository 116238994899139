import { Routes } from '@angular/router';

import { routes as adminRoutes } from './admin/admin.routes';
import { routes as authRoutes } from './auth/auth.routes';

export const routes: Routes = [
    ...adminRoutes,
    ...authRoutes,
    { path: '**', redirectTo: 'login', pathMatch: 'full' },
];
