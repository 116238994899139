import { Action, createReducer, on } from '@ngrx/store';

import { setUrl } from 'app/auth/store/redirect/redirect.actions';
import { environment } from 'environments/environment';

import { RedirectState } from './redirect.state';

export const redirectKey = 'redirect';

const initialState: RedirectState = {
    origin: environment.redirect.defaultOrigin,
    url: environment.redirect.defaultUrl,
};

const reducer = createReducer<RedirectState>(
    initialState,
    on(setUrl, (state, { url }) => {
        const domain = getDomain(url);
        if (!domain) {
            return { ...state, url };
        } else {
            const originDomain = getDomain(state.origin);
            if (originDomain && isSubdomain(domain, originDomain)) {
                return { ...state, url };
            } else {
                return { ...state };
            }
        }
    }),
);

export function redirectReducer(state: RedirectState | undefined, action: Action): RedirectState {
    return reducer(state, action);
}

function getDomain(url: string): string | null {
    const domainRegex = /(?<domain>\w+\.\w+)(?<path>\/.*)?$/; // matches the root domain, e.g. "hedgeserv.com" of "access.hedgeserv.com"
    const match = url.match(domainRegex);
    return match?.groups?.domain ?? null;
}

function isSubdomain(domain: string, origin: string): boolean {
    return !!domain.match(new RegExp(`${origin}$`));
}
