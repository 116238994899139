import { Routes } from '@angular/router';

import { IpWhitelistingGuard } from 'app/admin/guards/ip-whitelisting.guard';

export const routes: Routes = [
    {
        path: 'admin',
        loadComponent: async () => (await import('./admin.component')).AdminComponent,
        children: [
            { path: '', pathMatch: 'full', redirectTo: 'ip-whitelisting' },
            {
                path: 'ip-whitelisting',
                pathMatch: 'full',
                loadComponent: async () => (await import('./containers/ip-whitelisting-management/ip-whitelisting-management.component')).IpWhitelistingManagementComponent,
                canActivate: [IpWhitelistingGuard],
            },
        ],
    },
];
