import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ModalLayerComponent } from '@hs/ui-core-layout';
import { ToolTipComponent } from '@hs/ui-core-presentation';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
        ModalLayerComponent,
        RouterOutlet,
        ToolTipComponent,
    ],
})
export class AppComponent {
}
