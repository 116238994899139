import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';

import { MaintenanceWindowDto } from './dto';

@Injectable({
    providedIn: 'root',
})
export class MaintenanceWindowService {
    constructor(
        private readonly http: HttpClient,
    ) {}

    public fetchMaintenanceWindow(): Observable<MaintenanceWindowDto> {
        return this.http.get<MaintenanceWindowDto>(`${environment.api.baseUrl}/maintenance/window`);
    }
}
