export interface Environment {
    isProduction: boolean;
    dev: boolean;
    api: {
        baseUrl: string;
    };
    redirect: {
        defaultOrigin: string;
        defaultUrl: string;
    };
}

export const baseEnvironment: Environment = {
    isProduction: window.location.hostname.endsWith('hedgeserv.com'),
    dev: true,
    api: {
        baseUrl: `${window.location.origin}/webauth/api`,
    },
    redirect: {
        defaultOrigin: window.location.origin,
        defaultUrl: window.location.origin,
    },
};
