import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DeviceCapability, TwoFactorUserStatus } from 'app/auth/store/auth/auth.state';
import {
    CheckNotificationStatusResponseDTO,
    LoginAuthenticateResponseDTO,
    PasswordChangeRequestDTO,
    PasswordChangeResponseDTO,
    SendTwoFactorNotificationResponseDTO,
    Session,
    SessionDTO,
    UserDTO,
} from 'app/core/services/auth/dto';
import { LocalStorageService } from 'app/shared/services/local-storage/local-storage.service';
import { environment } from 'environments/environment';

export const TRUSTED_DEVICE_TOKEN_KEY = 'trusted-device-token';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private readonly http: HttpClient,
        private readonly localStorageService: LocalStorageService,
    ) {
    }

    loginSetup(username: string): Observable<{ token: string, trustedDeviceEnabled: boolean }> {
        return this.http.post<{ token: string, trustedDeviceEnabled: boolean }>(`${environment.api.baseUrl}/login/setup/`, { username });
    }

    authenticate(
        username: string,
        token: string,
        password: string,
        rememberDevice: boolean,
    ): Observable<LoginAuthenticateResponseDTO> {
        const body: { token: string, password: string, trustedDeviceToken?: string | null } = { token, password };

        if (rememberDevice) {
            const trustedDeviceToken = this.localStorageService.getItem(generateTrustedDeviceTokenKey(username));
            body.trustedDeviceToken = trustedDeviceToken ?? null;
        }

        return this.http.post<LoginAuthenticateResponseDTO>(`${environment.api.baseUrl}/login/authenticate/`, body);
    }

    checkTwoFactorNotificationStatus(): Observable<CheckNotificationStatusResponseDTO> {
        return this.http.get<CheckNotificationStatusResponseDTO>(`${environment.api.baseUrl}/login/2fa/notification-status`);
    }

    logout(): Observable<void> {
        return this.http.get<void>(`${environment.api.baseUrl}/logout`);
    }

    sendTwoFactorNotification(type: DeviceCapability, deviceId: string): Observable<SendTwoFactorNotificationResponseDTO> {
        return this.http.post<SendTwoFactorNotificationResponseDTO>(`${environment.api.baseUrl}/login/2fa/notification`, { type, deviceId });
    }

    getTwoFactorUserStatus(): Observable<TwoFactorUserStatus> {
        return this.http.get<TwoFactorUserStatus>(`${environment.api.baseUrl}/login/2fa/user-status`);
    }

    getUser(): Observable<UserDTO> {
        return this.http.get<UserDTO>(`${environment.api.baseUrl}/user`);
    }

    verifyPasscode(passcode: string): Observable<CheckNotificationStatusResponseDTO> {
        return this.http.post<CheckNotificationStatusResponseDTO>(`${environment.api.baseUrl}/login/2fa/passcode`, { passcode });
    }

    changePassword(passwordChangeData: PasswordChangeRequestDTO): Observable<PasswordChangeResponseDTO> {
        return this.http.post<PasswordChangeResponseDTO>(`${environment.api.baseUrl}/change-password`, passwordChangeData);
    }

    getSessionInfo(): Observable<Session> {
        return this.http.get<SessionDTO>(`${environment.api.baseUrl}/session-info`).pipe(
            map((session) => ({
                ...session,
                expires: new Date(session.expires),
                currentTime: new Date(session.currentTime),
            })),
        );
    }

    getTrustedDeviceToken(username: string): Observable<string | null> {
        return this.localStorageService.watchItem(generateTrustedDeviceTokenKey(username));
    }

    setTrustedDeviceToken(username: string, token: string): void {
        this.localStorageService.setItem(generateTrustedDeviceTokenKey(username), token);
    }

    clearTrustedDeviceToken(username: string): void {
        this.localStorageService.removeItem(generateTrustedDeviceTokenKey(username));
    }
}

function generateTrustedDeviceTokenKey(username: string): string {
    return `${TRUSTED_DEVICE_TOKEN_KEY} ${username}`;
}
